import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

// UI
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Step,
    StepButton,
    StepContent,
    Stepper,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import GroupIcon from '@mui/icons-material/Group';
import { useMask, format, unformat } from '@react-input/mask';

// Services
import StaffDocuments from './StaffDocuments';
import StaffQuestions from './StaffQuestions';
import { getStaffByCpf, getStaffPerId, postStaff, updateStaff } from 'services/staffs';
import { getTypeDocuments } from 'services/contracts';

const FormStaff = () => {
    const params = useParams();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [documents, setDocuments] = useState([]);

    const [cpfExists, setCpfExists] = useState(null);

    const maskCpfOptions = {
        mask: '___.___.___-__',
        replacement: { _: /\d/ }
    };

    const inputCpfRef = useMask(maskCpfOptions);

    const [errors, setErrors] = useState({});

    const handleStoreStaff = () => {
        postStaff({
            cpf: unformat(cpf, maskCpfOptions),
            nome: name,
            contratos: [
                {
                    contrato_id: contractSelected.id,
                    documentos: documents
                }
            ]
        })
            .then(() => {
                navigate('/efetivos');
            })
            .catch((error) => {
                setErrors(error.response.data.errors);
            });
    };

    const handleUpdateStaff = (id) => {
        updateStaff(id, {
            cpf: unformat(cpf, maskCpfOptions),
            nome: name,
            contratos: [
                {
                    contrato_id: contractSelected.id,
                    documentos: documents
                }
            ]
        })
            .then(() => {
                navigate('/efetivos');
            })
            .catch((error) => {
                setErrors(error.response.data.errors);
            });
    };

    const handleSubmit = () => {
        if (params.id || cpfExists) handleUpdateStaff(params.id || cpfExists);
        else handleStoreStaff();
    };

    const getExistsStaff = (id) => {
        getStaffPerId(id).then((response) => {
            const { data } = response.data;
            setCpf(format(data.cpf, maskCpfOptions));
            setName(data.nome);
            setDocuments(data.documentos.find((doc) => doc.contrato_id == contractSelected?.id).documentos);
        });
    };

    const getDefaultTypesDocuments = () => {
        getTypeDocuments().then((response) => {
            const { data } = response;
            setDocuments(data?.filter((doc) => doc.padrao).map((doc) => doc.id));
        });
    };

    const checkCpfExists = (value) => {
        getStaffByCpf(value).then((response) => {
            const { data } = response;
            if (data) {
                setName(data.nome);
                setCpfExists(data.id);
            } else {
                setName('');
                setCpfExists(false);
            }
        });
    };

    useEffect(() => {
        setCpfExists(false);
        if (cpf.length == 14) checkCpfExists(unformat(cpf, maskCpfOptions));
    }, [cpf]);

    useEffect(() => {
        if (params.id) getExistsStaff(params.id);
        else getDefaultTypesDocuments();
    }, [params]);

    useEffect(() => {
        if (!partnerSelected || !contractSelected) navigate('/efetivos');
    }, []);

    return (
        <MainCard
            sx={{ border: 0 }}
            title={
                <>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <GroupIcon />
                        <Typography variant="h2" fontSize={28}>
                            Cadastro de Efetivo
                        </Typography>
                    </Stack>
                    <Divider sx={{ mt: 2, borderColor: 'var(--unnamed-color-015641)' }} />
                </>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {partnerSelected && contractSelected && (
                        <Stack
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#ddd',
                                p: 4,
                                borderRadius: 2,
                                width: '100%'
                            }}
                        >
                            <Typography variant="h3">
                                {partnerSelected.nome_fantasia} - {contractSelected.numero}
                            </Typography>
                        </Stack>
                    )}
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label="CPF"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                        disabled={params.id}
                        inputRef={inputCpfRef}
                        //
                    />
                    {errors.cpf && (
                        <Typography sx={{ p: 1, fontSize: 12 }} color="error">
                            {errors.cpf[0]}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={params.id || cpfExists}
                        //
                    />
                    {errors.nome && (
                        <Typography sx={{ p: 1, fontSize: 12 }} color="error">
                            {errors.nome[0]}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {user?.perfil_id == 2 && <StaffDocuments documents={documents} setDocuments={setDocuments} />}
                    {user?.perfil_id == 1 && <StaffQuestions documents={documents} setDocuments={setDocuments} />}
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button variant="contained" color="secondary" onClick={() => navigate('/efetivos')} size="large" disableElevation>
                    Voltar
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} size="large" disableElevation>
                    Salvar
                </Button>
            </Stack>
        </MainCard>
    );
};

export default FormStaff;
