import { useEffect, useState } from 'react';

// UI
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import Toggle from 'react-toggle';

// Services
import { questions } from './questions';

const StaffQuestions = ({ documents, setDocuments }) => {
    const [workflows, setWorkflows] = useState(questions);
    const [workflowsSelected, setWorkflowsSelected] = useState({});

    const handleChangeQuestion = (e, item) => {
        setWorkflows([
            ...workflows.map((i) => {
                if (i.id != workflowsSelected) return i;
                return {
                    ...i,
                    questions: i.questions.map((q) => {
                        if (q.id != item.id) return q;
                        return { ...q, response: e.target.checked };
                    })
                };
            })
        ]);
    };

    const updateDocuments = () => {
        workflows.forEach((workflow) => {
            workflow.questions.forEach((question) => {
                if (question.response) {
                    question.documents.forEach((item) => {
                        if (!documents.includes(item)) setDocuments([...new Set([...documents, item])]);
                    });
                } else {
                    question.documents.forEach((item) => {
                        if (documents.includes(item)) setDocuments([...new Set([...documents.filter((doc) => doc != item)])]);
                    });
                }
            });
        });
    };

    const getResponses = () => {
        setWorkflows([
            ...workflows.map((workflow) => {
                const questions = workflow.questions.map((question) => {
                    let response = true;
                    question.documents.forEach((item) => {
                        if (!documents.includes(item)) response = false;
                    });
                    return { ...question, response: response };
                });
                return { ...workflow, questions };
            })
        ]);
    };

    useEffect(() => {
        updateDocuments();
    }, [workflows]);

    useEffect(() => {
        if (documents.length > 0) getResponses();
    }, [documents]);

    return (
        <>
            <Typography variant="h3">Documentos</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="workflow-select" shrink>
                            Workflow
                        </InputLabel>
                        <Select
                            labelId="workflow-select"
                            id="workflow-select"
                            value={workflowsSelected}
                            label="Workflow"
                            onChange={(e) => setWorkflowsSelected(e.target.value)}
                        >
                            {workflows.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {workflows
                        ?.find((wk) => wk.id == workflowsSelected)
                        ?.questions?.map((item) => (
                            <Stack
                                key={item.id}
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    py: 4,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <Toggle checked={item.response} onChange={(e) => handleChangeQuestion(e, item)} />
                                <Typography variant="subtitle1">{item.question}</Typography>
                            </Stack>
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

export default StaffQuestions;
