import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// UI
import {
    IconButton,
    SpeedDial,
    SpeedDialAction,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import Toggle from 'react-toggle';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// Services
import { deleteStaffContract, getStaffs, updateStaff } from 'services/staffs';

const TableStaffs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const page = useSelector((state) => state.staffs.page);
    const rowsPerPage = useSelector((state) => state.staffs.rowsPerPage);
    const nameStaff = useSelector((state) => state.staffs.nameStaff);

    const [staffs, setStaffs] = useState([]);
    const [paginate, setPaginate] = useState({});

    const handleStaffContract = (staff) => {
        if (!staff.possui_contrato) {
            updateStaff(staff.id, {
                cpf: staff.cpf,
                nome: staff.nome,
                contratos: [{ contrato_id: contractSelected?.id }]
            }).then(() => {
                getAllStaffs();
            });
        } else {
            deleteStaffContract(staff.id, contractSelected.id).then(() => {
                getAllStaffs();
            });
        }
    };

    const getAllStaffs = () => {
        const contract = contractSelected ? contractSelected.id : '';
        const partner = partnerSelected ? partnerSelected.id : '';

        getStaffs(page, rowsPerPage, nameStaff, contract, partner).then((response) => {
            const { data, total, current_page, per_page } = response.data;
            setStaffs(data);
            setPaginate({ total, current_page, per_page });
        });
    };

    const formatCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    useEffect(() => {
        getAllStaffs();
    }, [partnerSelected, contractSelected, page, rowsPerPage, nameStaff]);

    return (
        <>
            <TableContainer sx={{ borderRadius: '10px' }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>CPF</TableCell>
                            <TableCell>Contratado</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staffs.map((staff) => (
                            <TableRow key={staff.id}>
                                <TableCell>{staff.nome}</TableCell>
                                <TableCell>{formatCPF(staff.cpf)}</TableCell>
                                <TableCell>
                                    <Toggle id={`${staff.id}`} checked={staff.possui_contrato} onClick={() => handleStaffContract(staff)} />
                                </TableCell>
                                <TableCell>
                                    {staff.possui_contrato ? (
                                        <IconButton onClick={() => navigate({ pathname: `/efetivo/${staff.id}/edit` })}>
                                            <EditIcon />
                                        </IconButton>
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {staffs.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    Nenhum efetivo encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={4}
                                count={paginate.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                                labelRowsPerPage="Registros por página"
                                onPageChange={(e, newPage) => dispatch({ type: 'SET_PAGE_STAFF', payload: newPage })}
                                onRowsPerPageChange={(e) => dispatch({ type: 'SET_ROWS_PER_PAGE_STAFF', payload: e.target.value })}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial ariaLabel="SpeedDial basic example" sx={{ position: 'absolute', bottom: 16, right: 16 }} icon={<AddIcon />}>
                <SpeedDialAction
                    icon={<PersonAddIcon />}
                    tooltipTitle="Novo Efetivo"
                    onClick={() => navigate({ pathname: '/novo_efetivo' })}
                />
            </SpeedDial>
        </>
    );
};

export default TableStaffs;
